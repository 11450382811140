<template>
    <BaseLayout>
        <img  class="image-home-info banner-desktop" width="100%" :src="require('@/assets/images/banner/clearance-sale.jpg')" />
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32 banner-desktop"></div>
            <h1 class="text-header">Daftar Produk Clearance Sale</h1>
            <div class="break-16"></div>
        </div>
        <img  class="image-home-info banner-mobile" width="100%" :src="require('@/assets/images/banner/clearance-sale.jpg')" />
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32"></div>
            <!-- <div class="row no-gutters">
                <div class="col-6">
                    <h4 class="ol-product-content__sidebar-item-title" style="font-size:16px;font-weight:bold;">Ketegori Produk</h4>
                    <select class="ol-input" id="search" style="height:46px;width:95%" @change="filterProduct()" v-model="filterSearch">
                        <option value="">Tampilkan Semua</option>
                        <option value="sale clearance">Clearance</option>
                        <option value="sale display">Display</option>
                    </select>
                </div>
            </div>
            <div class="break-16"></div> -->
            <ProductList :products="products" /> 
        </div>
    </BaseLayout>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue"
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import SidebarLoader from '@/components/loading/product/Sidebar.vue'
import Testimonial from "@/components/home/Testimonial.vue";
import Videoshort from "@/components/home/Videoshort.vue";
export default {
    name:"CategorySpringbed",
    components:{
        BaseLayout,
        ProductList,
        ProductLoader,
        Testimonial,
        Videoshort,
        SidebarLoader
    },
    data(){
        return {
            typedText: "",
            textArray: ["anak-anak", "ayah dan ibu", "keluarga Indonesia"],
            typingDelay: 200,
            erasingDelay: 100,
            newTextDelay: 1000,
            textArrayIndex: 0,
            charIndex: 0,
            isTyping: false,
            items: [
                { expanded: false },
                { expanded: false },
                { expanded: false },
                // ... Add more items as needed
            ],
            filterCategories: "",
            filterSearch: "",
            loading:true,
            loadingSidebar:true,
            loadingRelated: false,
            list:{
                more:false,
                total:0,
            },
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
        }
    },
    computed:{
        ...mapGetters("product/list",["products","categories","filterParameters","filtered","categoryGroup","copiedFilter"]),
    },
    methods:{
        ...mapActions("product/list",["fetchProducts","fetchFilterParameters"]),
        filterProduct() {
            this.getProducts(true);
            // console.log();
        },
        async getProducts(firstPage = false,per_page=null){
            this.loading = true;

            if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            
            var categories = this.filterCategories;
            var search = this.filterSearch;
            
            if(search != ""){
                this.filteredBy.q = search;
            }else{
                this.filteredBy.q = "sale";
            }

            if(categories != ""){
                this.filteredBy.categories = [categories];
            }else{
                // this.filteredBy.categories = [64,65,66];
            }
            this.filteredBy.type = 'termurah';

            this.filteredBy.per_page = 500;

            this.$store.commit("product/list/setFiltered",this.filteredBy);

            const results = await this.fetchProducts();
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("product/list/setFiltered",{page:this.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data produk", type: "error", duration: 3000, })
            }
            
        },
        type() {
        if (this.charIndex < this.textArray[this.textArrayIndex].length) {
            this.isTyping = true;
            this.typedText += this.textArray[this.textArrayIndex].charAt(this.charIndex);
            this.charIndex++;
            setTimeout(this.type, this.typingDelay);
        } else {
            this.isTyping = false;
            setTimeout(this.erase, this.newTextDelay);
        }
        },
        erase() {
        if (this.charIndex > 0) {
            this.isTyping = true;
            this.typedText = this.textArray[this.textArrayIndex].substring(0, this.charIndex - 1);
            this.charIndex--;
            setTimeout(this.erase, this.erasingDelay);
        } else {
            this.isTyping = false;
            this.textArrayIndex++;
            if (this.textArrayIndex >= this.textArray.length) this.textArrayIndex = 0;
            setTimeout(this.type, this.typingDelay + 1100);
        }
        },
        toggleMenu(selectorName){
            $(selectorName).slideToggle("fast");
        },
        toggleMenuWithSelector(targetSelector){
            targetSelector.slideToggle("fast");
        },
        toggleAccordion(index) {
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].expanded = false;
            }
            this.items[index].expanded = !this.items[index].expanded;
        },
    },
    async mounted(){
        await Promise.all([
            this.getProducts(true),
        ]);
        setTimeout(this.type, this.newTextDelay + 250);

        document.title = 'Jual Springbed & Kasur Busa Harga Terbaik & Berkualitas | MORE Furniture';
        document.description = 'Jual springbed dan kasur busa Olympic Bed dengan kualitas terbaik anti kempes dan bergaransi resmi. Tersedia banyak warna yang bisa dipilih sesuaikan dengan desain kamar tidur.';
    },
    beforeUpdate(){
        if($('.ol-product-type__list').hasClass("slick-initialized slick-slider")){
            $('.ol-product-type__list').slick('unslick');
        }
    },
}
</script>

<style scoped lang="css">

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 8px 0;
  /* color: #364B7B; */
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #364B7B;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  color: #364B7B;
  border: 1px solid #364B7B;
}
.accordion button .accordion-title {
  /* padding: 1em 1.5em 1em 0; */
  display:inline-block;
  width:90%;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  /* top: 18px; */
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: #364B7B;
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: 100%;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 16px;
}


.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto
}
.animation p {
 
  
  font-weight: bold;
  /* letter-spacing: 0.1rem; */
  text-align: center;
  overflow: hidden;
}
.animation p span.typed-text {
  font-weight: bold;
  color: #364b7b;
}
.animation p span.cursor {
  display: inline-block;
  /* background-color: #ccc; */
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
.animation p span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0%  { background-color: #ccc; }
  49% { background-color: #ccc; }
  50% { background-color: transparent; }
  99% { background-color: transparent; }
  100%  { background-color: #ccc; }
}


.info-sertifikat{
    width:50%;
    margin:auto;
}

.info-kelebihan{
    width:70%;
    margin:auto;
}
.text-title{
    font-size:18px;
}

.text-subtitle{
    font-size:24px;
}

.text-header{
    font-size:30px;font-weight: bold;text-align:center;
}

.image-kelebihan{
    width:30%;
}

.image-sertifikat{
    height:100px;
}
.image-sertifikat-1{
    height: 55px;
}

.banner-desktop{
    display:block;
}

.banner-mobile{
    display:none;
}

@media(max-width:767px){
    .info-sertifikat{
        width:100%;    
    }

    .info-kelebihan{
        width:100%;
        margin:auto;
    }

    .text-title{
        font-size:14px;
    }

    .text-subtitle{
        font-size:16px;
    }

    .text-header{
        font-size:20px;font-weight: bold;text-align:center;
    }

    .image-kelebihan{
        width:50%;
    }

    .image-sertifikat{
        height: 60px;
    }

    .image-sertifikat-1{
        height: 35px;
    }

    .banner-desktop{
        display:none;
    }

    .banner-mobile{
        display:block;
    }
}
</style>